import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MDEditor from '@uiw/react-md-editor';
import "react-datepicker/dist/react-datepicker.css";

import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest } from '../redux/actionsAPI';
import {LABEL_EVENT} from '../econf/cnf'

import {dynamicSortMultiple, oValues, firstSortedValue} from "./_tools"

class ProductEditModal extends Component {
    state = {
        name: "",
        publicName: "",
        numberOfPeopleInvolved: 1,
        description: "",
        codeArticle: "",
        codeAnalytic: "",
        price: "",
    }

    editionsList = []

    componentDidMount() {
        this.setState ({eventEdition: firstSortedValue(this.props.productConfig.editionsDic, '*fullName').id})
        if (this.props.interface.editingProductId in this.props.productConfig.productsDic)
        {
            var el = this.props.productConfig.productsDic[this.props.interface.editingProductId]
            this.setState ({numberOfPeopleInvolved: el.numberOfPeopleInvolved, name: el.name, eventEdition: el.idEventEdition, description:el.description, codeArticle:el.codeArticle, codeAnalytic:el.codeAnalytic, price: el.price})
        }
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    render () {
        var that = this
        this.editionsList = oValues(this.props.productConfig.editionsDic).sort (dynamicSortMultiple('*fullName'))
        return (
            <Modal show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingProductId", "paramValue": null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>{(that.props.interface.editingProductId > 0) ? "Edit product":"Create product"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="post" onSubmit={(e)=>{e.preventDefault}}>
                        <div className="mb-3">
                        <div className="control">
                                <label htmlFor="eventEdition" className="form-label">{LABEL_EVENT} edition</label>
                                <select 
                                    name="eventEdition"
                                    id="eventEdition"
                                    className="form-control"
                                    required
                                    onChange={this.handleInputChange}
                                    value={this.state.eventEdition}
                                >
                                    {this.editionsList.map (function (ev) {
                                        return <option value={ev.id} key={"selEventEdition" + ev.id}>{ev.fullName}</option>
                                    })}
                                </select>
                            </div>
                            <div className="control">
                                <label htmlFor="name" className="form-label">Product title</label>
                                <input
                                    id="name"
                                    className="form-control"
                                    name="name"
                                    required
                                    onChange={this.handleInputChange}
                                    value={this.state.name}
                                    type="text"
                                />
                            </div>
                            <div className="control">
                                <label htmlFor="name" className="form-label">Title for online sales</label>
                                <input
                                    id="publicName"
                                    className="form-control"
                                    name="publicName"
                                    required
                                    onChange={this.handleInputChange}
                                    value={this.state.publicName}
                                    type="text"
                                />
                            </div>
                            <div className="control">
                                <label htmlFor="name" className="form-label">Number of people involved</label>
                                <input
                                    id="numberOfPeopleInvolved"
                                    className="form-control"
                                    name="numberOfPeopleInvolved"
                                    required
                                    onChange={this.handleInputChange}
                                    value={this.state.numberOfPeopleInvolved}
                                    type="number"
                                />
                            </div>
                            <div className="control">
                                <label htmlFor="code" className="form-label">Code article</label>
                                <input
                                    id="code"
                                    className="form-control"
                                    name="codeArticle"
                                    onChange={this.handleInputChange}
                                    value={this.state.codeArticle}
                                    type="text"
                                />
                            </div>
                            <div className="control">
                                <label htmlFor="code" className="form-label">Code analytic</label>
                                <input
                                    id="code"
                                    className="form-control"
                                    name="codeAnalytic"
                                    onChange={this.handleInputChange}
                                    value={this.state.codeAnalytic}
                                    type="text"
                                    disabled={true}
                                />
                            </div>
                            <div className="control">
                                <label htmlFor="price" className="form-label">Price</label>
                                <input
                                    id="price"
                                    className="form-control"
                                    name="price"
                                    onChange={this.handleInputChange}
                                    value={this.state.price}
                                    type="number"
                                />
                            </div>
                            <div className="control">
                                <label htmlFor="description" className="form-label">Description</label>
                                <MDEditor
                                    id="description"
                                    className="form-control"
                                    value={this.state.description}
                                    onChange={(value)=>{this.setState({description:value});}}
                                />
                            </div>
                        </div>    
                    </form>    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingProductId", "paramValue": null})}}>
                    Close
                </Button>
                <Button variant="primary" onClick={(e)=>{ this.props.dispatch (apiRequest({'endpoint': 'editProduct', "productId": that.props.interface.editingProductId, "newState":that.state}, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingProductId", "paramValue": null})) }}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        productConfig: state.data.productConfig
    }
}

export default connect(mapStateToProps)(ProductEditModal)