export const hasSalesProgramSplit = false
export const HORUS_CK = "ck_yxet92x5lby0hpv"
export const LABEL_CRM = "BDM"
export const LABEL_EVENT = "Event"
export const LABEL_QNOTE = "Booth"
export const DEFAULT_VALUE_LONG_NOTE = `-Publier sur le site du Design Market OUI / NON
-Publier sur le site Instagram OUI / NON
-Publier dans une News lettre DATE

-Type de stand : - Garden - Construit - Débalage
- Nombre de stand: N°
- Zone du stand (pour les horaires de montage) - Zone 1- Zone 2 - Zone 3
- Etudiant : - pour le montage N°
          - pour le démontage N°`