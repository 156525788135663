import React, { Component } from 'react';
import { connect } from 'react-redux'

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import CheckoutForm from "./CheckoutForm";

import {STRIPE_KEY} from "../econf/conf"

import {
    publicApiRequest,
    ON_LOAD_EDITION
} from '../redux/actionsAPI'

import {
    setNotification
} from '../redux/actions'
import { redirectTo } from '../redux/actionsAuth';

class EventRegistration extends Component
{
    md5 = require('md5');
    textFields = [["companyName", "Company"]]
    personTextFields = [["firstName", "First Name"],["lastName", "Last Name"], ["email", "email"], ["jobTitle", "Position"]]
    billingTextFields = [["billingAddr", "Street and house"], ["city", "City"], ["zipCode", "Postal code"], ['country', "Country"], ['billingVAT', "VAT Number"]]

    state = Object.assign({
        purchasePassed: null,
        regCode: '',
        regOption: undefined,
        termsAccepted: false,
        editionId: 0,
        vipMode: false,
        askForCountry: false,
        numberOfPeopleInvolved: 1
    }, ...this.textFields.map ((el) => ({ [el[0]]:"" })), ...this.personTextFields.map ((el) => ({ [el[0]]:"" })), ...this.billingTextFields.map ((el) => ({ [el[0]]:"" }))) 

    stripePromise = null



    
    getJsonFromUrl(url) {
        var query = url.substr(1);
        var result = {};
        query.split("&").forEach(function(part) {
            var item = part.split("=");
            result[item[0]] = decodeURIComponent(item[1]);
        });
        return result;
    }

    componentDidMount() {
        var params = this.getJsonFromUrl (this.props.urlParams)
        if (params.withCountry && params.withCountry.length > 2)
            this.setState ({askForCountry: true})
        if (params.regCode && params.regCode.length > 2)
            this.setState ({regCode: params.regCode, regOption: "code", vipMode: true, purchasePassed:"reg"})
        if (params.eventID)
        {
            this.setState ({editionId: parseInt(params.eventID)})
            this.props.dispatch ({type: ON_LOAD_EDITION, openEdition: {}})
            this.props.dispatch (publicApiRequest({'endpoint': 'getEventEditionForRegistration', 'editionId': parseInt(params.eventID)}))    
        }

        if (STRIPE_KEY)
            this.stripePromise = loadStripe(STRIPE_KEY);
    }

    componentDidUpdate () {
        var cdiv = document.getElementById('eventRegistrationForm')
        if (cdiv)
            window.parent.postMessage ({ 'newContentSize': cdiv.clientHeight}, "*")
    }


    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === 'password')
            this.setState ({pScore: zxcvbn(e.target.value)})
        if (e.target.name === 'regOption')
            this.setState ({numberOfPeopleInvolved: 1})
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };

    handleSubmit = e => {
        e.preventDefault();    
    }

    render() {
        var that = this

        if (that.props.stripeRedirect)
        {
            const appearance = {
                theme: 'stripe',
              };

            const options = {
                clientSecret: that.props.stripeRedirect,
                appearance,
              };
              
            return (
                <div className="pForm">
                    <Elements options={options} stripe={that.stripePromise}>
                        <CheckoutForm />
                    </Elements>  
                </div>
              ); 
        }

        var personalFields = []
        for (const pCnt of Array(that.state.numberOfPeopleInvolved).keys()) {
            personalFields.push (...that.personTextFields.map (function (fn, ic) {
                if ((!that.state[fn[0]+pCnt]) || that.state[fn[0]+pCnt].length < 2)
                    fieldsFilled = false
                return <div className="control" key={"ptf" + pCnt + '_' + ic}>
                    <label htmlFor={fn[0]+pCnt} className="form-label">{fn[1]} {(that.state.numberOfPeopleInvolved > 1) ? (" (Person " + (pCnt+1) + ")"):""} <span className='asterisk'>*</span></label>
                    <input
                        className="form-control"
                        name={fn[0]+pCnt}
                        onChange={that.handleInputChange}
                        value={that.state[fn[0] + pCnt]}
                        type="text"
                    />
                </div>
            }))
        }
        

        var hasPaidOptions = that.props.openEdition && that.props.openEdition.registrationConfig && (that.props.openEdition.registrationConfig.stripeProductsDetails && (that.props.openEdition.registrationConfig.stripeProductsDetails.length > 0))
        var regIntro = (that.props.openEdition && that.props.openEdition.registrationConfig && that.props.openEdition.registrationConfig.registrationIntro) ? that.props.openEdition.registrationConfig.registrationIntro : null
        var fieldsFilled = true
        var regCodeNotFilled = ((that.state.regOption === 'code') && (that.state.regCode.length < 2)) || ((that.state.regOption === 'specialAsk') && (that.props.openEdition.registrationConfig.specialOptionInputCaption) && (that.props.openEdition.registrationConfig.specialOptionInputCaption.length > 3) && (that.state.regCode.length < 2))
        return (
            (that.props.openEdition && that.props.openEdition.id) ?
                <div className="eventRegistrationForm" id="eventRegistrationForm">
                    {regIntro ? <div className='regIntro' dangerouslySetInnerHTML={{__html: regIntro}}></div>:null}
                    {hasPaidOptions ? 
                        <div className='buttonsContainer'>
                            <Row>
                                <Col xs={6}>
                                    <button className={"btn btn-primary ms-3 me-3 mb-3 " + ((that.state.purchasePassed === "reg") ? "unselected":"")} onClick={(e)=>{ that.setState ({purchasePassed:"paid", regOption:undefined})}}>{that.props.openEdition.registrationConfig.paidOptionCaption ? that.props.openEdition.registrationConfig.paidOptionCaption : "I want to purchase a ticket"}</button>
                                    {((!that.state.purchasePassed) && that.props.openEdition.registrationConfig.paidOptionSub) ? <div className='sub'>{that.props.openEdition.registrationConfig.paidOptionSub}</div>:null}
                                </Col>
                                <Col xs={6}>
                                    <button className={"btn btn-primary ms-3 me-3 mb-3 " + ((that.state.purchasePassed === "paid") ? "unselected":"")} onClick={(e)=>{ that.setState ({purchasePassed:"reg", regOption:undefined})}}>{that.props.openEdition.registrationConfig.freeOptionCaption ? that.props.openEdition.registrationConfig.freeOptionCaption : "I have a right for a free registration"}</button>
                                    {((!that.state.purchasePassed) && that.props.openEdition.registrationConfig.freeOptionSub) ? <div className='sub'>{that.props.openEdition.registrationConfig.freeOptionSub}</div>:null}
                                </Col>
                            </Row>
                        </div>:null
                    }
                    {(!hasPaidOptions) || (that.state.purchasePassed) ?
                        <div> 
                            {(!that.state.vipMode) && (that.props.openEdition.registrationConfig.paymentURL) ? 
                                <div className='purchaseBtnContainer'>
                                    <a href={that.props.openEdition.registrationConfig.paymentURL} target='_blank' className='btn btn-primary me-3 mb-3'>{that.props.openEdition.registrationConfig.paidOptionCaption ? that.props.openEdition.registrationConfig.paidOptionCaption : "I want to purchase a ticket"}</a>
                                    {that.props.openEdition.registrationConfig.keepOnlyPayment ? null : <div className='mb-3 mt-3 separator'>
                                        <span className='bar'/> or <span className='bar'/>
                                    </div>}
                                </div>: null}

                            {that.props.openEdition.registrationConfig.keepOnlyPayment ? null : <div>
                                <div className={"control radioSelect" + (that.state.vipMode ? " d-none":"")}>
                                        {(!hasPaidOptions) || that.state.purchasePassed === 'reg' ? 
                                            <div>
                                                {that.props.openEdition.registrationConfig.registrationCodes ? <div><input type="radio" name="regOption" onChange={that.handleInputChange} checked={that.state.regOption === "code"} value="code"/><label className="form-label" htmlFor="code">I received an invitation code</label></div>:null}
                                                <div><input  type="radio" name="regOption" onChange={that.handleInputChange} checked={that.state.regOption === "ask"} value="ask"/><label className="form-label" htmlFor="ask">{(that.props.openEdition.registrationConfig.askCaption) && (that.props.openEdition.registrationConfig.askCaption.length > 3) ? that.props.openEdition.registrationConfig.askCaption : "I work for the European Institutions or an NGO and would like to attend free of charge"}</label></div>
                                                {(that.props.openEdition.registrationConfig.specialOptionCaption && that.props.openEdition.registrationConfig.specialOptionCaption.length > 3) ? <div><input type="radio" name="regOption" onChange={that.handleInputChange} checked={that.state.regOption === "specialAsk"} value="specialAsk"/><label className="form-label" htmlFor="specialAsk">{that.props.openEdition.registrationConfig.specialOptionCaption}</label></div>:null}
                                            </div>:
                                            <div>
                                                {hasPaidOptions ? that.props.openEdition.registrationConfig.stripeProductsDetails.map (function (sp, ic) {
                                                    var pID = 'regStripe' + sp['id']
                                                    return <div key={pID}><input  type="radio" name="regOption" onChange={(e)=>{that.setState({regOption:pID, numberOfPeopleInvolved:sp['numberOfPeopleInvolved']})}} checked={that.state.regOption === pID} value={pID}/><label className="form-label" htmlFor={pID}>{sp['publicName']} - {sp['price']} Eur HTVA</label></div>
                                                }):null}
                                            </div>}
                                </div>    
                                {that.state.regOption === 'code' ? 
                                    <div className={"control" + (that.state.vipMode ? " d-none":"")}>
                                        <label htmlFor="regCode" className="form-label">Invitation code<span className='asterisk'>*</span></label>
                                        <input
                                            className="form-control"
                                            name="regCode"
                                            onChange={that.handleInputChange}
                                            value={that.state.regCode}
                                            type="text"
                                        />
                                    </div>:null}
                                {(that.state.regOption === 'specialAsk') && (that.props.openEdition.registrationConfig.specialOptionInputCaption) && (that.props.openEdition.registrationConfig.specialOptionInputCaption.length > 3) ? 
                                    <div className={"control"}>
                                        <label htmlFor="regCode" className="form-label">{that.props.openEdition.registrationConfig.specialOptionInputCaption}<span className='asterisk'>*</span></label>
                                        <input
                                            className="form-control"
                                            name="regCode"
                                            onChange={that.handleInputChange}
                                            value={that.state.regCode}
                                            type="text"
                                        />
                                    </div>:null}

                                {personalFields}
                                
                                
                                
                                
                                
                                {this.textFields.map (function (fn, ic) {
                                    if ((!that.state[fn[0]]) || that.state[fn[0]].length < 2)
                                        fieldsFilled = false
                                    return <div className="control" key={"tf" + ic}>
                                        <label htmlFor={fn[0]} className="form-label">{fn[1]} <span className='asterisk'>*</span></label>
                                        <input
                                            className="form-control"
                                            name={fn[0]}
                                            onChange={that.handleInputChange}
                                            value={that.state[fn[0]]}
                                            type="text"
                                        />
                                    </div>
                                })}
                                {that.state.askForCountry ? <div className="control">
                                        <label htmlFor="country" className="form-label">Country <span className='asterisk'>*</span></label>
                                        <input
                                            className="form-control"
                                            name="country"
                                            onChange={that.handleInputChange}
                                            value={that.state["country"]}
                                            type="text"
                                        />
                                    </div>:null}

                                {that.state.regOption && that.state.regOption.indexOf("regStripe") !== -1 ? 
                                    <div className='billing'>
                                        <div className="control">
                                            <label className="form-label">Billing address</label>
                                        </div>
                                        
                                        {this.billingTextFields.map (function (fn, ic) {
                                            if (((!that.state[fn[0]]) || that.state[fn[0]].length < 2) && (fn[0] !== 'billingVAT'))
                                                fieldsFilled = false
                                            return <div className="control" key={"btf" + ic}>
                                                <label htmlFor={fn[0]} className="form-label">{fn[1]} {(fn[0] !== 'billingVAT') ? <span className='asterisk'>*</span>:null}</label>
                                                <input
                                                    className="form-control"
                                                    name={fn[0]}
                                                    onChange={that.handleInputChange}
                                                    value={that.state[fn[0]]}
                                                    type="text"
                                                />
                                            </div>
                                        })}
                                    </div>:null}

                                

                                <div className="control">
                                    <input
                                        className="form-check-input"
                                        name="termsAccepted"
                                        onChange={that.handleCBChange}
                                        checked={that.state["termsAccepted"]}
                                        type="checkbox"
                                    />
                                    <label htmlFor="termsAccepted" className="form-check-label ms-2"> I Accept EBS Terms and Conditions</label>
                                </div>
                                            
                                                    
                                <div className="control mt-1 buttonsContainer">
                                    <button className={"float-right btn btn-primary" + (this.state.termsAccepted && fieldsFilled && (!regCodeNotFilled) && (this.state.regOption) ? "" : " disabled")} disabled={!(this.state.termsAccepted && fieldsFilled && (!regCodeNotFilled) && this.state.regOption)} onClick={(e)=>{
                                        if ((that.state.regOption === 'ask') || (that.state.regOption === 'pressRequest') || (that.state.regOption === 'specialAsk'))
                                            this.props.dispatch (publicApiRequest({'endpoint': 'registerToEvent', 'newState': that.state}, {type: ON_LOAD_EDITION, openEdition: {}}))       
                                        if (that.state.regOption === 'pay')
                                            this.props.dispatch (publicApiRequest({'endpoint': 'registerToEvent', 'newState': that.state}, redirectTo(that.props.openEdition.registrationConfig.paymentURL)))       
                                        if (that.state.regOption === 'code')
                                        {
                                            if (that.props.openEdition.registrationConfig.registrationCodes.includes (that.md5(that.state.regCode))) {
                                                this.props.dispatch (publicApiRequest({'endpoint': 'registerToEvent', 'newState': that.state}, {type: ON_LOAD_EDITION, openEdition: {}}))    
                                            }
                                            else {
                                                that.props.dispatch(setNotification ("Error occured.", "Incorrect invitation code. Please check"))
                                            }
                                        }
                                        if (that.state.regOption.indexOf("regStripe") !== -1) 
                                            this.props.dispatch (publicApiRequest({'endpoint': 'registerToEvent', 'newState': that.state}))       
                                        
                                    }}>Submit</button>
                                </div>
                            </div>}
                        </div>:
                        null}
                </div> : <div className="eventRegistrationForm"><h4>{that.props.inlineMessage}</h4></div>
        );
    }
}

function mapStateToProps(state) {
    return {
        openEdition: state.data.openEdition,
        inlineMessage: state.interfaceParams.inlineMessage,
        stripeRedirect: state.interfaceParams.stripeRedirect
    }
}

export default connect(mapStateToProps)(EventRegistration)
