import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Creatable from 'react-select/creatable';
import Table from 'react-bootstrap/Table';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest, apiRequestWithFiles, ON_LOAD_CONTACT } from '../redux/actionsAPI';
import Row from 'react-bootstrap/Row';
import { LABEL_EVENT } from '../econf/cnf'

import {dynamicSortMultiple, oValues, productValueLabel, valueLabel} from "./_tools"


class ContactEditModal extends Component {
    textFields = [["firstName", "First Name"],["lastName", "Last Name"], ["jobTitle", "Job title"],["email", "e-mail"], ["phone", "Landline"],["gsm","Mobile phone"]]
    state =  Object.assign({ selectedFile: undefined, selectedTab: "Contact", contentContact: false, isModerator: false, salesContact: false, participationInEventAsASpeaker:false, participationInEvent:{value:0, label:"----"}, tags: [], language: "en", title: "Mr", company: valueLabel(oValues(this.props.contactConfig.companiesDic).sort (dynamicSortMultiple("*name"))[0], "id", "name" )}, ...this.textFields.map ((el) => ({ [el[0]]:"" }))) 
    companiesList = []
    editionsList = []
    tagsList = []

    componentDidUpdate(prevProps) {
        if (prevProps.openContact.id !== this.props.openContact.id) {
            var cs = {}
            var cc = this.props.openContact
            if (cc.id === this.props.interface.editingContactId)
            {
                for (var tf of this.textFields)
                    if (cc[tf[0]])
                        cs[tf[0]] = cc[tf[0]]
                if (cc.idCompany > 0)
                    cs['company'] = {value:cc.idCompany, label:this.props.contactConfig.companiesDic[cc.idCompany].name}
                cs['language'] = cc.language
                cs['title'] = cc.title
                cs['tags'] = []
                for (var t of cc.idTags)
                    cs['tags'].push(this.props.tagsConfig.dicStructure[t])
                cs['contentContact'] = cc.contentContact
                cs['isModerator'] = cc.isModerator
                cs['salesContact'] = cc.salesContact
                this.setState (cs)
            }
        }
    }
    
    componentDidMount() {
        if (this.props.interface.editingContactId > 0)
        {
            this.props.dispatch ({type: ON_LOAD_CONTACT, openContact: {}})
            this.props.dispatch (apiRequest({'endpoint': 'getContact', 'contactId': this.props.interface.editingContactId}))    
        }
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };

    handleRadioChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    render () {
        var that = this
        var contents = null
        this.companiesList = oValues(this.props.contactConfig.companiesDic).sort (dynamicSortMultiple("*name"))
        this.editionsList = oValues (this.props.productConfig.editionsDic).sort (dynamicSortMultiple('*fullName'))
        this.tagsList = oValues(that.props.tagsConfig.externalDic).sort (dynamicSortMultiple("*label"))
        
        
        if (this.state.selectedTab === 'Contact')
            contents = 
                    <form method="post" onSubmit={(e)=>{e.preventDefault}}>
                        <div className="mb-3">
                            <Row>
                                <Col md={(this.props.openContact && this.props.openContact.photo) ? 8:12}>
                                    <div className="control">
                                        <Button variant='link' className="float-end" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingCompanyId", "paramValue": 0})}}>+ Add company</Button>
                                        <label htmlFor="company" className="form-label">Company</label>
                                        <Select
                                            name="company"
                                            id="company"
                                            onChange={that.handleSelectChange}
                                            value={that.state.company}
                                            tmp_menuPosition="fixed"
                                            options={ that.companiesList.map (function (el) {
                                                return {value:el.id, label:el.name}
                                            })}
                                        />
                                    </div>    
                                    <div className="control">
                                        <label htmlFor="title" className="form-label">Title</label>
                                        <div className='mb-1'>
                                            <Form.Check
                                                inline
                                                label="Mr"
                                                name="title"
                                                type="radio"
                                                checked={that.state.title === "Mr"}
                                                onChange={that.handleRadioChange}
                                                value="Mr"
                                            />
                                            <Form.Check
                                                inline
                                                label="Ms"
                                                name="title"
                                                type="radio"
                                                checked={that.state.title === "Ms"}
                                                onChange={that.handleRadioChange}
                                                value="Ms"
                                            />
                                            <Form.Check
                                                inline
                                                label="Other"
                                                name="title"
                                                type="radio"
                                                checked={that.state.title === "Other"}
                                                onChange={that.handleRadioChange}
                                                value="Other"
                                            />
                                        </div>
                                    </div>
                                    {this.textFields.map (function (fn, ic) {
                                        return <div className="control" key={"tf" + ic}>
                                        <label htmlFor={fn[0]} className="form-label">{fn[1]}</label>
                                        <input
                                            id={fn[0]}
                                            className="form-control"
                                            name={fn[0]}
                                            onChange={that.handleInputChange}
                                            value={that.state[fn[0]]}
                                            type="text"
                                        />
                                    </div>
                                    })}
                                    <div className="control">
                                        <label htmlFor="attachment" className="form-label">Set new photo</label>
                                        <Form.Control type="file" name="attachment" onChange={(e) => {that.setState({ selectedFile: e.target.files[0]});}} accept="jpg" />
                                    </div>

                                    <div className="control">
                                        <label htmlFor="language" className="form-label">Language</label>
                                        <select 
                                            name="language"
                                            id="language"
                                            className="form-control"
                                            onChange={that.handleInputChange}
                                            value={that.state.language}
                                        >
                                            <option value="nl">Dutch</option>
                                            <option value="en">English</option>
                                            <option value="fr">French</option>
                                        </select>
                                    </div>
                                    {[['contentContact', 'Speaker'], ['isModerator', 'Moderator'], ['salesContact', 'Sales contact']].map (function (fn, ic) {
                                                return <div className={"control control" + fn[0]} key={"cb" + ic}>
                                                <input
                                                    id={fn[0]}
                                                    className="form-check-input"
                                                    name={fn[0]}
                                                    onChange={that.handleCBChange}
                                                    checked={that.state[fn[0]]}
                                                    type="checkbox"
                                                />
                                                <label htmlFor={fn[0]} className="form-check-label ms-2">{fn[1]}</label>
                                            </div>
                                            })}
                                    <div className="control">
                                        <label htmlFor="tags" className="form-label">Tags</label>
                                        <Creatable 
                                            name="tags"
                                            id="tags"
                                            onChange={that.handleSelectChange}
                                            value={that.state.tags}
                                            isMulti={true}
                                            options={that.tagsList}
                                        />
                                    </div>
                                </Col>
                                {(this.props.openContact && this.props.openContact.photo) ? <Col md={4}><img className='mt-3 contactPhoto' src={"data:image/png;base64," + this.props.openContact.photo }></img></Col>:null}
                            </Row>
                        </div>    
                    </form>    
        if (this.state.selectedTab === 'Tasks')
        {
            var lines = this.props.openContact.idTasks.map (function (pID, ic) {
                var p = that.props.tasks[pID]
                if (!p)
                    return null
                return <tr className={p.hotState ? "task hot": "task"}  key={"task"+ic} role="button" onClick={(e)=>{if ((p.idUser === that.props.profile.id) || (that.props.profile.isAdmin)) that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": p.id})}}>
                    <td>{p.idProduct > 0 ? productValueLabel(p.idProduct, that.props.productConfig).label : ""}</td>
                    <td>{p.proposalAmount} {p.proposalDate}</td>
                    <td>{p.hotState ? <i className={"fs-4 bi-check2"}></i>:null }</td>
                    <td>{p.taskStep.label}</td>
                    <td>{p.nextActionDate}</td>
                    <td>{p.updateDate}</td>
                    <td>{p.closeDate}</td>
                    <td>{(p.idUser > 0) ? that.props.users[p.idUser].fullName : null}</td>
                </tr>
            })
            contents = <div className="mt-3">
                <Button className="float-end btn-primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "selectedContactId", "paramValue": this.props.openContact.id}); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": 0})}}>+ Add</Button>
                <h3>Tasks</h3>
                <Table responsive className="table-hover tasksTable">
                    <thead>
                        <tr>
                            <th>Products</th>
                            <th>Proposal</th>
                            <th>Is hot</th>
                            <th>Action</th>
                            <th>Next Action</th>
                            <th>Updated</th>
                            <th>Closed</th>
                            <th>Responsible</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }
        if (this.state.selectedTab === 'Sales')
        {
            var lines = that.props.openContact.idSales.map (function (pID, ic) {
                var p = that.props.sales [pID]
                if (!p)
                    return null
                
                return <tr key={"sale"+ic} role='button' onClick={(e)=>{if (((p.idBy === that.props.profile.id) || (that.props.profile.isAdmin))) that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": p.id})}}>
                    <td>{p.transactionDate}</td>
                    <td>{p.products.map (function (pp, ic) { return pp.idProduct > 0 ? (productValueLabel(pp.idProduct, that.props.productConfig).label + " ") : "" }) } {p.oneTimeProduct ? p.oneTimeProduct : ""}</td>
                    <td>{p.agreedPrice}</td>
                    <td>{p.quantity}</td>
                    <td>{(p.idBy.value > 0) ? that.props.users[p.idBy].fullName : p.historicBy }</td>
                </tr>
            })
            contents = <div className="mt-3">
                <Table responsive className="table-hover salesTable">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Products</th>
                            <th>HTVA</th>
                            <th>QTE</th>
                            <th>By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }
        if (this.state.selectedTab === 'Participation')
        {
            var lines = this.props.openContact.participation.map (function (p, ic) {
                var eed = that.props.productConfig.editionsDic[p.idEventEdition]
                return <tr key={"task"+ic}>
                    <td>{eed.fullName}</td>
                    <td>{eed.dateStart} {eed.dateEnd ? ("- " + eed.dateEnd):""}</td>
                    <td>{p.asSpeaker ? <i className={"fs-4 bi-check2"}></i>:null}</td>
                    <td><Button variant="secondary" onClick={(e)=>{that.props.dispatch (apiRequest({'endpoint': 'removeParticipation', "contactId": that.props.interface.editingContactId, "participationID":p.id}))}}><i className={"bi-trash"}></i></Button></td>
                </tr>
            })
            contents = <div className="mt-3">
                <h3>Add new {LABEL_EVENT} participation</h3>
                <form method="post" onSubmit={(e)=>{e.preventDefault}}>
                    <div className="mb-3">
                        <div className="control">
                            <Select 
                                name="participationInEvent"
                                id="participationInEvent"
                                className="mb-3"
                                onChange={that.handleSelectChange}
                                value={that.state["participationInEvent"]}
                                options={[{value:0, label:"----"}].concat (that.editionsList.map (function (ed) { return {value: ed.id, label: ed.fullName}}))}
                            />
                            <div className="control mb-3">
                                <input
                                    className="form-check-input"
                                    name="participationInEventAsASpeaker"
                                    onChange={that.handleCBChange}
                                    checked={that.state["participationInEventAsASpeaker"]}
                                    type="checkbox"
                                />
                                <label htmlFor="participationInEventAsASpeaker" className="form-check-label ms-2">As a speaker</label>
                            </div>
                            <Button variant="primary" onClick={(e)=>{that.props.dispatch (apiRequest({'endpoint': 'addParticipation', "contactId": that.props.interface.editingContactId, "eventEdition":that.state.participationInEvent.value, "asSpeaker": that.state.participationInEventAsASpeaker}))}}>Add</Button>
                        </div>
                    </div>
                </form>
                <h3>History</h3>
                <Table responsive className="table-hover tasksTable">
                    <thead>
                        <tr>
                            <th>{LABEL_EVENT} edition</th>
                            <th>Date</th>
                            <th>As speaker</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }

        if (this.state.selectedTab === 'HTasks')
        {
            var lines = this.props.openContact.historicTasks.map (function (p, ic) {
                return <tr key={"htask"+ic}>
                    <td>{p.company.company}</td>
                    <td>{p.product.title}</td>
                    <td>{p.proposal}</td>
                    <td>{(p.actionN !== '') ? p.actionN.tasks : ''}</td>
                    <td>{p.next_action}</td>
                    <td>{p.date_edit}</td>
                    <td>{p.cloture}</td>
                    <td>{p.username}</td>
                    <td>{p.comment.split("\n").map((i,key) => {
                            return <div key={key}>{i}</div>;
                        })}</td>
                </tr>
            })
            contents = <div className="mt-3">
                <h3>List of historic tasks from the previous CRM</h3>
                <Table responsive className="table-hover tasksTable">
                    <thead>
                        <tr>
                            <th>In organisation</th>
                            <th>Products</th>
                            <th>Proposal</th>
                            <th>Action</th>
                            <th>Next action</th>
                            <th>Edited</th>
                            <th>Closed</th>
                            <th>User</th>
                            <th>Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }

        if (this.state.selectedTab === 'Communications')
        {
            var lines = this.props.openContact.communications.map (function (p, ic) {
                return <tr key={"comm"+ic} role='button' onClick={(e)=>{ that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "viewingMailId", "paramValue": p.id})}}>
                    <td>{p.date}</td>
                    <td>{(p.idBy > 0) ? that.props.users[p.idBy].fullName : null}</td>
                    <td>{p.subject}</td>
                </tr>
            })
            contents = <div className="mt-3">
                <h3>List of messages sent from the CRM</h3>
                <Table responsive className="table-hover commsTable">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>By</th>
                            <th>Subject</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }
                
        
        return <Modal size={(this.props.interface.editingContactId > 0) ? 'xl':'m'}  show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingContactId", "paramValue": null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>{(that.props.interface.editingContactId > 0) ? "Contact details":"Create contact"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(this.props.interface.editingContactId > 0) ? <Nav variant="tabs" justify
                        activeKey={this.state.selectedTab}
                        onSelect={(selectedKey) => this.setState ({selectedTab:selectedKey})}>
                        <Nav.Item>
                            <Nav.Link eventKey="Contact">Card</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='tasksRelated'>
                            <Nav.Link eventKey="Tasks">Tasks</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="Sales">Sales</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="Participation">Participation</Nav.Link>
                        </Nav.Item>
                        {this.props.openContact && this.props.openContact.communications && this.props.openContact.communications.length > 0 ? <Nav.Item>
                            <Nav.Link eventKey="Communications">Communications</Nav.Link>
                        </Nav.Item>:null}
                        {this.props.openContact && this.props.openContact.historicTasks && this.props.openContact.historicTasks.length > 0 ? <Nav.Item className='historicSalesRelated'>
                            <Nav.Link eventKey="HTasks">Historic tasks</Nav.Link>
                        </Nav.Item>:null}
                    </Nav>: null}
                    {contents}
                </Modal.Body>
                <Modal.Footer>
                    {that.props.interface.editingContactId ?
                        <Button variant="secondary" className='me-auto' onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "sendingMailParams", "paramValue": { "receivers":[{"id":that.props.interface.editingContactId, "name": that.state.firstName + ' ' + that.state.lastName}]  }})}}>
                            Send a mail
                        </Button> : null }
                    
                    <Button variant="secondary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingContactId", "paramValue": null})}}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e)=>{ 
                        for (var tfn of [["firstName", "First Name"],["lastName", "Last Name"], ["jobTitle", "Job title"],["email", "e-mail"]])
                            if (!that.state[tfn[0]])
                            {
                                alert ("Please fill in the " + tfn[1])
                                return
                            }
                        this.props.dispatch (apiRequestWithFiles({'endpoint': 'editContact', "contactId": that.props.interface.editingContactId, "newState":that.state}, {"file":that.state.selectedFile}, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingContactId", "paramValue": null})) }}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        contactConfig: state.data.contactConfig,
        productConfig: state.data.productConfig,
        tasks: state.data.tasks,
        sales: state.data.sales,
        openContact: state.data.openContact,
        tagsConfig: state.data.tagsConfig,
        profile: state.data.profile,
        users: state.data.users
    }
}

export default connect(mapStateToProps)(ContactEditModal)