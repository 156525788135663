import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import Form from 'react-bootstrap/Form';

import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequestWithFiles } from '../redux/actionsAPI';
import {LABEL_EVENT} from '../econf/cnf'

import moment from "moment"

import {dynamicSortMultiple, oValues, firstSortedValue} from "./_tools"

class HolidayEditModal extends Component {
    
    state = {
        dateStart: null,
        dateStartT: null,
        dateEnd: null,
        dateEndT: null,
        onlyMorning: false,
        onlyAfternoon: false,
        absenceCollaborator: {value:0, label:'---'},
        note: "",
        selectedFile: undefined, 
    }

    componentDidMount() {
        var hid = this.props.interface.editingHolidayId ? this.props.interface.editingHolidayId[0] : 0
        if (hid in this.props.holidays)
        {
            var el = this.props.holidays[hid]
            const ds = el.dateStart ? moment (el.dateStart, 'DD-MM-YYYY').toDate() : null
            const de = el.dateEnd ? moment (el.dateEnd, 'DD-MM-YYYY').toDate() : null
            var cs = {note:el.note, dateStart:ds, dateStartT:ds ? ds.getTime():ds, dateEnd: de, dateEndT:de ? de.getTime():de,
                    onlyMorning: el.onlyMorning, onlyAfternoon:el.onlyAfternoon
            }
            this.setState (cs)
        }
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };
    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    render () {
        var that = this
        var hid = 0
        var isAbsence = false
        if (that.props.interface.editingHolidayId)
        {
            hid = that.props.interface.editingHolidayId[0]
            isAbsence = that.props.interface.editingHolidayId[1]
        }

        var selOptions = {
            'absenceCollaborator': oValues (this.props.users).filter(function (el) {
                return el.enabled
            }).sort(dynamicSortMultiple ('*fullName')).map (function (el) {
                return {value:el.id, label: el.fullName}
            }),
        }
        

        return (
            <Modal show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingHolidayId", "paramValue": null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>{isAbsence ? "Edit absence": ((hid > 0) ? "Edit holidays demand":"Create holidays demand")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="post" onSubmit={(e)=>{e.preventDefault}}>
                        <div className="mb-3">
                            <div className="control">
                                <label htmlFor="name" className="form-label">Start date</label>
                                <DatePicker 
                                    selected={that.state.dateStart} 
                                    dateFormat="dd-MM-yyyy"
                                    calendarStartDay={1}
                                    onChange={(date) => { that.setState ({dateStart:date, dateStartT: date ? date.getTime():date}); }}/>
                                <input
                                    className="form-check-input"
                                    name="onlyAfternoon"
                                    onChange={that.handleCBChange}
                                    checked={that.state["onlyAfternoon"]}
                                    type="checkbox"
                                />
                                <label htmlFor="onlyAfternoon" className="form-check-label ms-2">only afternoon</label>
                                
                            </div>
                            <div className="control mt-3">
                                <label htmlFor="name" className="form-label">End date</label>
                                <DatePicker 
                                    selected={that.state.dateEnd} 
                                    dateFormat="dd-MM-yyyy"
                                    calendarStartDay={1}
                                    onChange={(date) => { that.setState ({dateEnd:date, dateEndT: date ? date.getTime(): date});}}/>
                                <input
                                    className="form-check-input"
                                    name="onlyMorning"
                                    onChange={that.handleCBChange}
                                    checked={that.state["onlyMorning"]}
                                    type="checkbox"
                                />
                                <label htmlFor="onlyMorning" className="form-check-label ms-2">only morning</label>
                            </div>
                            <div className="control mt-3">
                                <label htmlFor="note" className="form-label">Note</label>
                                <input
                                            className="form-control"
                                            name="note"
                                            onChange={that.handleInputChange}
                                            value={that.state["note"]}
                                            type="text"
                                        />
                            </div>
                            <div className="control mt-3">
                                    <label htmlFor="attachment" className="form-label">Attachment (optional)</label>
                                    <Form.Control type="file" name="attachment" onChange={(e) => {that.setState({ selectedFile: e.target.files[0]});}} accept="*" />
                                </div>
                            {isAbsence ? <div>
                                {[['absenceCollaborator', 'Person']].map (function (sel, ic) {
                                        return <div className="mt-3" key={"sel_" + ic}>
                                                    <label className="form-label">{sel[1]}</label>
                                                    <div>
                                                        <Select 
                                                            menuPosition="fixed"
                                                            name={sel[0]}
                                                            id={sel[0]}
                                                            onChange={that.handleSelectChange}
                                                            value={that.state[sel[0]]}
                                                            options={[{value:0, label:"----"}].concat (selOptions[sel[0]])}
                                                        />
                                                    </div>
                                                </div>

                                    })}
                            
                            </div>:null}
                        </div>    
                    </form>    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingHolidayId", "paramValue": null})}}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={(e)=>{ this.props.dispatch (apiRequestWithFiles({'endpoint': 'editHoliday', "holidayId": hid, "isAbsence":isAbsence, "newState":that.state}, {"file":that.state.selectedFile}, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingHolidayId", "paramValue": null})) }}>
                    Send the demand
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        holidays: state.data.holidays,
        profile: state.data.profile,
        users: state.data.users
    }
}

export default connect(mapStateToProps)(HolidayEditModal)