import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Editor from 'react-simple-wysiwyg';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment"




import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest } from '../redux/actionsAPI';
import { dynamicSortMultiple, oValues, valueLabel, firstValue } from './_tools';


class NewsletterTypeEditModal extends Component {
    textFields = [['name', "Name"], ['publicName', 'Public Name'], ['bannerURL',"Banner URL"], ['subscriptionTitle', 'Subscription caption'], ['sendGridTemplate', 'SendGrid welcome template'], ['senderName', 'Sendgrid sender name'], ['flexmailInterest', 'Flexmail interest ID']]
    cbFields = [['subscriptionEnabled', 'Enable subscription to newsletter'], ['published', 'Is published at the portal'], ['isDefault', 'Is default at the portal']]
    state =  Object.assign({ }, ...this.textFields.map ((el) => ({ [el[0]]:"" }))) 
    
    componentDidMount() {
        var cs = {}
        if (this.props.interface.editingNewsletterTypeId in this.props.newslettersConfig.types)
        {
            var el = this.props.newslettersConfig.types[this.props.interface.editingNewsletterTypeId]
            for (var tf of this.textFields)
            {
                if (tf[0] in el)
                    cs[tf[0]] = el[tf[0]]
                else if (tf[0] in el['settings'])
                    cs[tf[0]] = el['settings'][tf[0]]
            }
            for (var tf of this.cbFields)
            {
                if (tf[0] in el)
                    cs[tf[0]] = el[tf[0]]
                else if (tf[0] in el['settings'])
                    cs[tf[0]] = el['settings'][tf[0]]
            }
        }
        this.setState (cs)
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };
    
    onHTMLChange = e => {
        this.setState({ content: e.target.value });
    }

    render () {
        var that = this
        
        return (
            <Modal show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingNewsletterTypeId", "paramValue": null})}}>
                <Modal.Header closeButton className='mb-3'>
                    <Modal.Title>{(that.props.interface.editingNewsletterTypeId > 0) ? "Edit type":"Create type"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="post" onSubmit={(e)=>{e.preventDefault}} className='mb-3'>
                        <div className="mb-3">
                            {this.textFields.map (function (fn, ic) {
                                return <div className="control mb-3" key={"tf" + ic}>
                                <label htmlFor={fn[0]} className="form-label">{fn[1]}</label>
                                
                                <input
                                        id={fn[0]}
                                        className="form-control"
                                        name={fn[0]}
                                        onChange={that.handleInputChange}
                                        value={that.state[fn[0]]}
                                        type="text"
                                    />
                            </div>
                            })}

                            {this.cbFields.map (function (fn, ic) {
                                return <div className="control" key={"cb" + ic}>
                                <input
                                    id={fn[0]}
                                    className="form-check-input"
                                    name={fn[0]}
                                    onChange={that.handleCBChange}
                                    checked={that.state[fn[0]]}
                                    type="checkbox"
                                />
                                <label htmlFor={fn[0]} className="form-check-label ms-2">{fn[1]}</label>
                            </div>
                            })}
                        </div>    
                    </form>    
                </Modal.Body>
                <Modal.Footer className='pb-3 pt-3 mb-3 mt-3'>
                <Button variant="secondary" className='mt-3' onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingNewsletterTypeId", "paramValue": null})}}>
                    Close
                </Button>
                <Button variant="primary" className='mt-3' onClick={(e)=>{ this.props.dispatch (apiRequest({'endpoint': 'newsletterTypeEdit', "typeId": that.props.interface.editingNewsletterTypeId, "newState":that.state}, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingNewsletterTypeId", "paramValue": null})) }}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        newslettersConfig: state.data.newslettersConfig,
    }
}

export default connect(mapStateToProps)(NewsletterTypeEditModal)