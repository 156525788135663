import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import Select from 'react-select';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import { apiRequest } from "../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../redux/actions";
import Button from "react-bootstrap/esm/Button";

import moment from "moment"

import {hasSalesProgramSplit} from "../econf/cnf"

import {dynamicSortMultiple, oValues, productValueLabel} from "./_tools"


class ContactsBrowser extends Component {
    state = {
        selectedTab: "Contacts",
        tagFilter: [],
        nameFilter: "",
        cnameFilter: "",
        companyFilter: "",
        jobFilter: "",
        cTypeFilter: {value:0, label:"All"},
        showHot: true,
        showMembers: true,
        showExpired: true,
        stringTagFilter: "",
        contactsExpanded: false
    }

    companiesList = []
    contactsList = []
    externalTagsList = []

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };

    numberWithSpacesWithoutDecimals(x) {
        if (!x)
            return x
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
        return parts[0]
        //return parts.join(".");
    }

    differenceInMonthsFromToday (d0Str) {
        var d0 = moment (d0Str, 'DD-MM-YYYY')
        var d1 = moment ()
        d0.set({hour:12,minute:0,second:0,millisecond:0})
        d1.set({hour:12,minute:0,second:0,millisecond:0})
        var diff = -d0.diff(d1, 'months')
        return diff
    }

    differenceInDaysFromTodayIsIn(d0Str, v1, v2) {
        var d0 = moment (d0Str, 'DD-MM-YYYY')
        var d1 = moment ()
        d0.set({hour:12,minute:0,second:0,millisecond:0})
        d1.set({hour:12,minute:0,second:0,millisecond:0})
        var diff = d0.diff(d1, 'days')
        var isIn = true
        if (v1 !== null)
            isIn = isIn && (diff >= v1)
        if (v2 !== null)
            isIn = isIn && (diff <= v2)
        return isIn
      }

    checkSelectedTagFilter ()
    {
        if (this.props.interface.selectedTagFilter) {
            this.setState ({tagFilter: this.props.interface.selectedTagFilter})
            this.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "selectedTagFilter", "paramValue": null});
        }
    }

    componentDidMount() {
        this.props.dispatch (apiRequest({'endpoint': 'getContacts'}))
        if (hasSalesProgramSplit)
        {
            if ((this.props.profile.isSales) && (!this.props.profile.isProgram))
                this.setState ({ cTypeFilter: {value:1, label:"Sales"}, })
            if ((!this.props.profile.isSales) && (this.props.profile.isProgram))
                this.setState ({ cTypeFilter: {value:2, label:"Speaker"}, })
        }
        //this.checkSelectedTagFilter ()
    }

    componentDidUpdate(prevProps) {
        
        if ((hasSalesProgramSplit) && (prevProps.profile.isSales !== this.props.profile.isSales)) {
            if ((this.props.profile.isSales) && (!this.props.profile.isProgram))
                this.setState ({ cTypeFilter: {value:1, label:"Sales"}, })
            if ((!this.props.profile.isSales) && (this.props.profile.isProgram))
                this.setState ({ cTypeFilter: {value:2, label:"Speaker"}, })   
        }
        this.checkSelectedTagFilter ()
    }

    render () {
        var contents = null
        var that = this
        this.companiesList = oValues(this.props.contactConfig.companiesDic).sort (dynamicSortMultiple("*name"))
        this.contactsList = oValues(this.props.contactConfig.contactsDic).sort (dynamicSortMultiple("*user__last_name", "*user__first_name"))
        this.externalTagsList = oValues(this.props.tagsConfig.externalDic).filter(function (el) {
                return that.props.tagsConfig.membersDic.contacts[el.value].length > 0
            }).sort (dynamicSortMultiple("*label"))

        if (this.state.selectedTab === 'Companies')
        {
            var lines = this.companiesList.map (function (p, ic) {
                if ((that.state.cnameFilter.length > 0) && (! (p.name).toLowerCase().includes (that.state.cnameFilter.toLowerCase()) )&& (! (p.shortName).toLowerCase().includes (that.state.cnameFilter.toLowerCase()) ))
                    return null
                
                return <tr role="button" key={"company"+ic} onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingCompanyId", "paramValue": p.id})}}>
                    <td>{p.name}</td>
                    <td>{p.address}</td>
                    <td>{p.zipCode} {p.city}</td>
                    <td>{p.country}</td>
                    <td>{p.website}</td>
                    <td>{p.vat}</td>
                    <td>{p.idTags.map (function(t,icc) { return (that.props.tagsConfig.dicStructure[t]) ? <span key={"t" + ic + "_" + icc} style={{backgroundColor:that.props.tagsConfig.dicStructure[t].color}} className="p-2 rounded me-1 d-inline-block">{that.props.tagsConfig.dicStructure[t].label}</span>:null })}</td>
                    <td>{(p.idFavoriteContact > 0) ? <Button>{that.props.contactConfig.contactsDic[p.idFavoriteContact].fullName}</Button>:null}</td>
                    <td>{(p.idInvoiceContact > 0) ? <Button>{that.props.contactConfig.contactsDic[p.idInvoiceContact].fullName}</Button>:null}</td>
                </tr>
            })
            contents = <div className="mt-3">
                <Button className="float-end btn-primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingCompanyId", "paramValue": 0})}}>+ Add</Button>
                <h3>Companies</h3>
                <Table responsive className="table-hover">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>Country</th>
                            <th>Website</th>
                            <th>VAT</th>
                            <th>Tags</th>
                            <th>Favourite contact</th>
                            <th>Purchase contact</th>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    className="form-control"
                                    name="cnameFilter"
                                    onChange={that.handleInputChange}
                                    value={that.state["cnameFilter"]}
                                    type="text"
                                />
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }

        if (this.state.selectedTab === 'Contacts')
        {
            var filteredCount = 0
            var lines = this.contactsList.map (function (p, ic) {
                if (that.props.tagsConfig.membersDic) 
                    for (var tagFilter of that.state.tagFilter)
                        if (!that.props.tagsConfig.membersDic.contacts[tagFilter.value].includes (p.id))
                            return null
                if ((that.state.nameFilter.length > 0) && (! (p.fullName + ' ' + p.user__email).toLowerCase().includes (that.state.nameFilter.toLowerCase()) ))
                    return null
                if ((that.state.companyFilter.length > 0) && ((p.idCompany <= 0) || (!that.props.contactConfig.companiesDic[p.idCompany]) || ((!that.props.contactConfig.companiesDic [p.idCompany].shortName.toLowerCase().includes (that.state.companyFilter.toLowerCase()) ) && (! that.props.contactConfig.companiesDic [p.idCompany].name.toLowerCase().includes (that.state.companyFilter.toLowerCase()) ))))
                    return null
                if ((that.state.jobFilter.length > 0) &&  ((!p.jobTitle) || (! p.jobTitle.toLowerCase().includes (that.state.jobFilter.toLowerCase()) )))
                    return null
                if ((that.state.cTypeFilter.value === 1) && (!p.salesContact))
                    return null
                if ((that.state.cTypeFilter.value === 2) && (!p.contentContact))
                    return null
                if ((that.state.cTypeFilter.value === 3) && (!p.isModerator))
                    return null
                
                filteredCount += 1
                return <tr role="button" key={"contact"+ic} onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingContactId", "paramValue": p.id})}}>
                    <td>{p.fullName}  ({p.user__email})</td>
                    <td>{(p.idCompany > 0) ? that.props.contactConfig.companiesDic[p.idCompany].name : null}</td>
                    <td>{p.jobTitle}</td>
                    <td className="salesProgramSplitRelated">{p.salesContact ? <i className={"fs-4 bi-check2"}></i>:<i className={"fs-4 bi-x"}></i> } / {p.contentContact ? <i className={"fs-4 bi-check2"}></i>:<i className={"fs-4 bi-x"}></i> } / {p.isModerator ? <i className={"fs-4 bi-check2"}></i>:<i className={"fs-4 bi-x"}></i> } </td>
                    <td></td>
                </tr>
            })
            contents = <div className="mt-3">
                <Button className="float-end btn-primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingContactId", "paramValue": 0})}}>+ Add</Button>
                <Button className="float-end btn-secondary me-3 importContactButton" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "importingContactId", "paramValue": 0})}}>+ Import</Button>
                <h3>Contacts ({filteredCount})</h3>
                {that.props.tagsConfig.membersDic ? <div>
                    <label htmlFor="tagFilte">Filter by tags:</label>
                    <Select 
                        menuPosition="fixed"
                        name="tagFilter"
                        onChange={that.handleSelectChange}
                        isMulti={true}
                        value={that.state["tagFilter"]}
                        options={this.externalTagsList.map (function (el) {
                            return {value:el.value, label: el.label}
                        })}
                    />
                    {(that.state.tagFilter.length > 0) ?<Button variant="link" onClick={(e)=>{ that.props.dispatch (apiRequest({'endpoint': 'addWidget', "type": "tagFilter", "tagFilter": that.state.tagFilter})) }}>Save filter as a preset</Button>:null}
                </div>:null}
                <Table responsive className="table-hover">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Organisation</th>
                            <th>Job title</th>
                            <th className="salesProgramSplitRelated">Sales / Speaker / Moderator</th>
                            <th>Actions</th>

                        </tr>
                        <tr>
                            <td>
                                <input
                                    className="form-control"
                                    name="nameFilter"
                                    onChange={that.handleInputChange}
                                    value={that.state["nameFilter"]}
                                    type="text"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    name="companyFilter"
                                    onChange={that.handleInputChange}
                                    value={that.state["companyFilter"]}
                                    type="text"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    name="jobFilter"
                                    onChange={that.handleInputChange}
                                    value={that.state["jobFilter"]}
                                    type="text"
                                />
                            </td>
                            <td className="salesProgramSplitRelated">
                                <Select 
                                    menuPosition="fixed"
                                    name="cTypeFilter"
                                    onChange={that.handleSelectChange}
                                    value={that.state["cTypeFilter"]}
                                    options={[{value:0, label:"All"},{value:1, label:"Sales"},{value:2, label:"Speaker"},{value:3, label:"Moderator"}]}
                                />
                            </td>
                            <td></td>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }

        if (this.state.selectedTab === 'Members')
        {
            var cnt = 0
            var totalPrice = 0
            var unconsumedPrice = 0
            var lines = this.companiesList.filter(function(p) { return p.lastMembershipTransaction !== null }).sort(
                (t1, t2) => (moment(t1.lastMembershipTransaction.membershipStartDate, 'DD-MM-YYYY') < moment(t2.lastMembershipTransaction.membershipStartDate, 'DD-MM-YYYY')) ? -1 : (moment(t1.lastMembershipTransaction.membershipStartDate, 'DD-MM-YYYY') > moment(t2.lastMembershipTransaction.membershipStartDate, 'DD-MM-YYYY')) ? 1 : 0).map (function (p, ic) {
                if ((that.state.cnameFilter.length > 0) && (! (p.name).toLowerCase().includes (that.state.cnameFilter.toLowerCase()) ) && (! (p.shortName).toLowerCase().includes (that.state.cnameFilter.toLowerCase()) ))
                    return null
                if (that.differenceInDaysFromTodayIsIn (p.lastMembershipTransaction.membershipStartDate, null, -365))
                    return null
                var tagsPass = that.state.stringTagFilter.length === 0
                for (var ct of p.idTags)
                    if (that.props.tagsConfig.dicStructure[ct].label.toLowerCase().includes (that.state.stringTagFilter.toLocaleLowerCase()))
                    {
                        tagsPass = true
                        break
                    }
                if (!tagsPass)
                    return null
                cnt += 1
                totalPrice += p.lastMembershipTransaction.agreedPrice
                var cPri = p.lastMembershipTransaction.agreedPrice - (that.differenceInMonthsFromToday (p.lastMembershipTransaction.membershipStartDate) + 1) * p.lastMembershipTransaction.agreedPrice / 12
                unconsumedPrice += cPri
                return <tr className={"memberline " + (that.differenceInDaysFromTodayIsIn (p.lastMembershipTransaction.membershipStartDate, null, -365) ? "expired ":"") + (that.differenceInDaysFromTodayIsIn (p.lastMembershipTransaction.membershipStartDate, -364, -335) ? "expiresSoon ":"")+ (that.differenceInDaysFromTodayIsIn (p.lastMembershipTransaction.membershipStartDate, -334, -273) ? "expires3M ":"")} role="button" key={"company"+ic} onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": p.lastMembershipTransaction.id})}}>
                    <td><i className={"fs-4 bi-person-check"}/>{cnt}</td>
                    <td>{p.shortName}</td>
                    <td>Until {p.memberUntil}</td>
                    <td>{p.lastMembershipTransaction.products.map (function (pp, ic) { return pp.idProduct > 0 ? (productValueLabel(pp.idProduct, that.props.productConfig).label + " ") : "" }) }  {p.lastMembershipTransaction.oneTimeProduct ? p.lastMembershipTransaction.oneTimeProduct : ""}</td>
                    <td>{that.numberWithSpacesWithoutDecimals (p.lastMembershipTransaction.agreedPrice)}</td>
                    <td>{that.numberWithSpacesWithoutDecimals (cPri)}</td>
                    <td>{that.props.contactConfig.contactsDic [p.lastMembershipTransaction.idContact].fullName}</td>
                    <td>{p.lastMembershipTransaction.byInitials}</td>
                    <td>{p.idTags.map (function(t,icc) { return <span key={"t" + ic + "_" + icc} style={{backgroundColor:that.props.tagsConfig.dicStructure[t].color}} className="p-2 rounded me-1 d-inline-block">{that.props.tagsConfig.dicStructure[t].label}</span> })}</td>
                </tr>
            })
            var totalNumber = cnt
            cnt = 0
            var explines = this.companiesList.filter(function(p) { return p.lastMembershipTransaction !== null }).sort(
                (t1, t2) => (moment(t1.lastMembershipTransaction.membershipStartDate, 'DD-MM-YYYY') < moment(t2.lastMembershipTransaction.membershipStartDate, 'DD-MM-YYYY')) ? -1 : (moment(t1.lastMembershipTransaction.membershipStartDate, 'DD-MM-YYYY') > moment(t2.lastMembershipTransaction.membershipStartDate, 'DD-MM-YYYY')) ? 1 : 0).map (function (p, ic) {
                if ((that.state.cnameFilter.length > 0) && (! (p.name).toLowerCase().includes (that.state.cnameFilter.toLowerCase()) ))
                    return null
                if (!that.differenceInDaysFromTodayIsIn (p.lastMembershipTransaction.membershipStartDate, null, -365))
                    return null
                var tagsPass = that.state.stringTagFilter.length === 0
                for (var ct of p.idTags)
                    if (that.props.tagsConfig.dicStructure[ct].label.toLowerCase().includes (that.state.stringTagFilter.toLocaleLowerCase()))
                    {
                        tagsPass = true
                        break
                    }
                if (!tagsPass)
                    return null
                cnt += 1
                return <tr className={"memberline " + (that.differenceInDaysFromTodayIsIn (p.lastMembershipTransaction.membershipStartDate, null, -365) ? "expired ":"")} role="button" key={"company"+ic} onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": p.lastMembershipTransaction.id})}}>
                    <td><i className={"fs-4 bi-clock-history"}/>{cnt}</td>
                    <td>{p.shortName}</td>
                    <td>Expired on {p.wasMemberUntil}</td>
                    <td>{p.lastMembershipTransaction.products.map (function (pp, ic) { return pp.idProduct > 0 ? (productValueLabel(pp.idProduct, that.props.productConfig).label + " ") : "" }) }  {p.lastMembershipTransaction.oneTimeProduct ? p.lastMembershipTransaction.oneTimeProduct : ""}</td>
                    <td>{that.numberWithSpacesWithoutDecimals (p.lastMembershipTransaction.agreedPrice)}</td>
                    <td></td>
                    <td>{that.props.contactConfig.contactsDic [p.lastMembershipTransaction.idContact].fullName}</td>
                    <td>{p.lastMembershipTransaction.byInitials}</td>
                    <td>{p.idTags.map (function(t,icc) { return <span key={"t" + ic + "_" + icc} style={{backgroundColor:that.props.tagsConfig.dicStructure[t].color}} className="p-2 rounded me-1 d-inline-block">{that.props.tagsConfig.dicStructure[t].label}</span> })}</td>
                </tr>
            })
            var e_totalNumber = cnt

            var h_cnt = 0
            var h_lines = oValues (this.props.tasks).filter (function(p) { return p.hotState && (!p.closeDate) }).sort(
                (t1, t2) => (moment(t1.updateDate, 'DD-MM-YYYY') < moment(t2.updateDate, 'DD-MM-YYYY')) ? 1 : (moment(t1.updateDate, 'DD-MM-YYYY') > moment(t2.updateDate, 'DD-MM-YYYY')) ? -1 : 0).map (function (p, ic) {
                    var pContact = (p.idContact > 0) ? that.props.contactConfig.contactsDic[p.idContact] : {idCompany:0}
                    if (pContact.idCompany < 0)
                        return null
                    var pCompany = that.props.contactConfig.companiesDic[pContact.idCompany]
                    if ((that.state.cnameFilter.length > 0) && (! (pCompany.name.toLowerCase().includes (that.state.cnameFilter.toLowerCase()) )))
                        return null
                    var tagsPass = that.state.stringTagFilter.length === 0
                    for (var ct of pCompany.idTags)
                        if (that.props.tagsConfig.dicStructure[ct].label.toLowerCase().includes (that.state.stringTagFilter.toLocaleLowerCase()))
                        {
                            tagsPass = true
                            break
                        }
                    if (!tagsPass)
                        return null

                    var contact = pContact.fullName
                    if (that.state.contactsExpanded)
                    {
                        contact = pCompany.idContacts.map (function(cid) {
                            return that.props.contactConfig.contactsDic[cid]
                        }).sort ((t1,t2) => (t1.fullName.toLowerCase() < t2.fullName.toLowerCase() ? -1 : 1)).map ( function (ccont) {
                            return <div key={"ccc" + ccont.id} onClick={(e) => { e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingContactId", "paramValue": ccont.id})}}>{ccont.fullName}</div>
                        })
                    }

            
                    h_cnt += 1
                    return <tr className={"memberline"} role="button" key={"company"+ic} onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": p.id})}}>
                        <td><i className={"fs-4 bi-fire"}></i> {h_cnt} {(p.forMembership && !pCompany.wasMember) ? <i className={"fs-4 bi-person-plus"}></i>:null}</td>
                        <td>{pCompany.shortName}</td>
                        <td>Upd. on {p.updateDate}</td>
                        <td>{productValueLabel(p.idProduct, that.props.productConfig).label}</td>
                        <td>{p.proposalAmount} {p.proposalDate}</td>
                        <td></td>
                        <td>{contact}</td>
                        <td>{p.byInitials}</td>
                        <td>{pCompany.idTags.map (function(t,icc) { return <span key={"t" + ic + "_" + icc} style={{backgroundColor:that.props.tagsConfig.dicStructure[t].color}} className="p-2 rounded me-1 d-inline-block">{that.props.tagsConfig.dicStructure[t].label}</span> })}</td>
                    </tr>
            })
            var h_totalNumber = h_cnt
            
            contents = <div><Row className="mt-3">
                <Col md={6}>
                    <h4><input
                                    className="form-check-input me-2"
                                    name="showHot"
                                    onChange={that.handleCBChange}
                                    checked={that.state["showHot"]}
                                    type="checkbox"
                        />
                            {h_totalNumber} Hot Companies <i className={"fs-4 bi-fire"}></i></h4>
                    <h4><input
                                    className="form-check-input me-2"
                                    name="showMembers"
                                    onChange={that.handleCBChange}
                                    checked={that.state["showMembers"]}
                                    type="checkbox"
                        />
                            {totalNumber} Members <i className={"fs-4 bi-person-check"}></i></h4>
                    <h4><input
                                    className="form-check-input me-2"
                                    name="showExpired"
                                    onChange={that.handleCBChange}
                                    checked={that.state["showExpired"]}
                                    type="checkbox"
                        />
                            {e_totalNumber} Expired <i className={"fs-4 bi-clock-history"}></i></h4>
                </Col>
                <Col md={6}>
                    <h4 style={{textAlign:"end"}}><input
                                    className="form-check-input me-2"
                                    name="contactsExpanded"
                                    onChange={that.handleCBChange}
                                    checked={that.state["contactsExpanded"]}
                                    type="checkbox"
                        />
                            Show all contacts</h4>
                </Col>
            </Row>
            <div>
                <div>
                    <Table responsive className="table-hover mt-3">
                    {that.state.showHot ? <thead>
                            <tr>
                                <th></th>
                                <th>Company name</th>
                                <th>Last updated</th>
                                <th>Product</th>
                                <th>Proposal</th>
                                <th></th>
                                <th>Contact</th>
                                <th>Responsible</th>
                                <th>Tags</th>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <input
                                        className="form-control"
                                        name="cnameFilter"
                                        onChange={that.handleInputChange}
                                        value={that.state["cnameFilter"]}
                                        type="text"
                                    />
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <input
                                        className="form-control"
                                        name="stringTagFilter"
                                        onChange={that.handleInputChange}
                                        value={that.state["stringTagFilter"]}
                                        type="text"
                                    />
                                </td>
                                
                            </tr>
                        </thead>:null}
                        {that.state.showHot ? <tbody>
                            {h_lines}
                            <div className="endsWithPageBreak"></div>
                        </tbody>:null}
                        {(that.state.showMembers || that.state.showExpired) ? <thead>
                            <tr>
                                <th></th>
                                <th>Company name</th>
                                <th>Member until</th>
                                <th>With product</th>
                                <th>With price</th>
                                <th>Unconsumed price</th>
                                <th>Contact</th>
                                <th>Responsible</th>
                                <th>Tags</th>
                            </tr>
                            {(!that.state.showHot) ? <tr>
                                <td></td>
                                <td>
                                    <input
                                        className="form-control"
                                        name="cnameFilter"
                                        onChange={that.handleInputChange}
                                        value={that.state["cnameFilter"]}
                                        type="text"
                                    />
                                </td>
                                <td></td>
                                <td></td>
                                {that.props.profile.isAdmin ? <td>Total active: {that.numberWithSpacesWithoutDecimals (totalPrice)}</td>:<td></td>}
                                {that.props.profile.isAdmin ? <td>Total not-consumed: {that.numberWithSpacesWithoutDecimals (unconsumedPrice)}</td>:<td></td>}
                                <td></td>
                                <td></td>
                                <td>
                                    <input
                                        className="form-control"
                                        name="stringTagFilter"
                                        onChange={that.handleInputChange}
                                        value={that.state["stringTagFilter"]}
                                        type="text"
                                    />
                                </td>
                                
                            </tr>:null}
                        </thead>:null}
                        <tbody>
                            {that.state.showMembers ? lines : null}
                            <div className="endsWithPageBreak"></div>
                            {that.state.showExpired ? explines : null}
                            <div className="endsWithPageBreak"></div>
                        </tbody>
                    </Table>
                </div>
                
            </div>
            </div>
        }

        
        return <div className="contactsBrowser">
            <Nav variant="tabs" justify
                activeKey={this.state.selectedTab}
                onSelect={(selectedKey) => this.setState ({selectedTab:selectedKey})}>
                <Nav.Item>
                    <Nav.Link eventKey="Companies">Companies</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Contacts">Contacts</Nav.Link>
                </Nav.Item>
                { that.props.profile.isAdmin ? <Nav.Item className="membershipRelated">
                    <Nav.Link eventKey="Members">Members / Hot companies</Nav.Link>
                </Nav.Item>:null}
            </Nav>
  
            {contents}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        contactConfig: state.data.contactConfig,
        productConfig: state.data.productConfig,
        tagsConfig: state.data.tagsConfig,
        isAdmin: state.data.profile.isAdmin,
        profile: state.data.profile,
        tasks: state.data.tasks
    }
}

export default connect(mapStateToProps)(ContactsBrowser)